@media print {
  header,
  footer,
  .pro-sidebar {
    display: none;
  }

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}
