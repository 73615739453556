body {
  display: grid;
  grid-template-areas: "nav main";
  grid-template-columns: auto 1fr;
}

label {
  display: block;
  font-size: 10pt;
  font-weight: bold;
}

img {
  max-height: 100%;
  max-width: 100%;
}

input {
  background-color: var(--color-background);
  border: var(--color-primary) 1px solid;
  font-size: 10pt;
  height: 1.7rem;
  margin-bottom: 8px;
  padding: 0 10px 0 10px;
}

input[type="checkbox"] {
  height: 0.8rem;
}

button {
  background-color: var(--color-primary);
  border: var(--color-primary) 1px solid;
  color: #fff;
  margin-top: 10px;
  padding: 5px 7px;
}

#sidebar-footer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.sidebar-footer-icons {
  cursor: pointer;
  font-size: 22px;
  height: 25px;
  margin: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-footer-icons:hover {
  color: var(--color-primary);
}

.break-line {
  min-width: 200px;
  white-space: normal;
  word-break: break-word;
}

.button-new {
  color: white;
  padding-bottom: 3px;
  padding-top: 3px;
}

.gradient-background {
  background: hsla(189, 100%, 41%, 1);
  background: linear-gradient(135deg, hsla(189, 100%, 41%, 1) 0%, hsla(211, 100%, 28%, 1) 100%);
  background: -moz-linear-gradient(135deg, hsla(189, 100%, 41%, 1) 0%, hsla(211, 100%, 28%, 1) 100%);
  background: -webkit-linear-gradient(135deg, hsla(189, 100%, 41%, 1) 0%, hsla(211, 100%, 28%, 1) 100%);
  filter: 'progid: DXImageTransform.Microsoft.gradient( startColorstr="#00AFCF", endColorstr="#00458E", GradientType=1 )';
}

.paper-header {
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: var(--color-primary);
  font-size: 30px;
  margin-bottom: 15px;
}

.sidebar-header {
  display: flex;
  justify-content: flex-end;
  padding-left: 20px;
  padding-top: 10px;
}

.table-edit-bar {
  background-color: #e8f4fd;
}

.icon {
  cursor: pointer;
}

.icon:hover {
  color: var(--color-primary);
}

.sidebar-header-label {
  flex: auto;
  font-size: 25px;
  justify-content: center;
  padding-right: 30px;
}

.sidebar-header-userinfo {
  display: flex;
  margin: 15px 10px 5px 10px;
}

.sidebar-header-userinfo img {
  flex-shrink: 1;
  width: 60px;
}

.sidebar-header-userinfo label {
  margin-left: 10px;
}

.pro-sidebar.collapsed .sidebar-header-userinfo label {
  display: none;
}

.sidebar-footer-dropdown {
  background-color: white;
  border: #d8d8d8 1px solid;
  text-align: center;
}

.main-title {
  font-size: 14pt;
}

.login-area {
  grid-area: main;
}

.main-content {
  background-color: var(--color-background);
  grid-area: main;
  min-height: 100vh;
  overflow: scroll;
  padding: 10px;
  position: relative;
}

.main-content-body {
  min-height: 85%;
}

.import-export {
  align-items: flex-start;
  display: flex;
  margin-top: 10px;
}

.import-export button {
  border-radius: 4px;
  margin: 0 30px 0 10px;
}

.footer {
  bottom: 0;
  color: #ccc;
  font-size: 11pt;
  margin: 30px 0 10px 0;
  position: relative;
  text-align: center;
  width: 100%;
}

.correction {
  color: #ff2a00;
}

.corrected {
  text-decoration: line-through;
}

section.error {
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}

section.minor-error {
  color: var(--color-error);
  margin-top: 30px;
  text-align: center;
}

.main-area {
  grid-area: main;
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex-grow {
  flex-grow: 1;
}

.flex-1 {
  flex: 1;
}

.flex-column {
  flex-direction: column;
}

.flex-shrink {
  flex-shrink: 1;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.m-8 {
  margin: 8px;
}

.m-16 {
  margin: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mr-20 {
  margin-right: 20px;
}

.w-160 {
  width: 160px;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.login-card {
  width: 160px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-56 {
  padding-top: 56px;
}

.pr-24 {
  padding-right: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.f-30 {
  font-size: 30px;
}

.f-26 {
  font-size: 26px;
}

.f-20 {
  font-size: 20px;
}

.border-dashed-3 {
  border: 3px dashed rgba(0, 0, 0, 0.12);
}

.c-red {
  color: red;
}

.MuiDropzoneArea-root .MuiGrid-grid-xs-4 {
  flex-basis: unset !important;
  margin-left: 8px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.inventory-row-yellow {
  background-color: rgba(255, 199, 6, 0.4) !important;
}

.inventory-row-red {
  background-color: rgba(255, 42, 0, 0.4) !important;
}

.cart-row-orange {
  background-color: rgba(248, 147, 124, 0.6) !important;
}

.grid-row-border {
  border-top: 1px solid #a5a5a5;
}

.grid-row-bold {
  font-weight: bold;
}

.cart-return-view {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  text-align: center;
  width: 500px;
}

.cart-return-text {
  color: rgba(248, 147, 124, 0.6);
  transform: rotate(-45deg);
}
