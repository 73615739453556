@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

:root {
  --color-primary: #00afcf;
  --color-background: #f2f2f2;
  --color-error: #ff2a00;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background: var(--color-background);
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

.validation-error {
  display: none;
}
