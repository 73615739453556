section.error-boundary {
  align-items: center;
  background: rgba(255, 255, 255, 1);
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  height: 50%;
  justify-content: center;
  left: 25%;
  min-width: 400px;
  padding: 50px;
  position: absolute;
  top: 25%;
  width: 50%;
}

section.error-boundary p {
  width: 100%;
}

section.error-boundary button {
  display: block;
}
